import React, { Component } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import moment from 'moment-timezone'

import Box from '@material-ui/core/Box'
import MenuIcon from '@material-ui/icons/Menu'

import { GA_ACTION, GA_EVENT, sendGAEvent } from '../analytics'
import { GRAPHQL_AUTH_CLIENT_ID, GRAPHQL_AUTH_DOMAIN, TODAY } from '../constants'

import LoginAuth0 from './LoginAuth0'

import './Header.css'

import logo from '../images/theatre-roulette.webp'

class Header extends Component {

  state = {
    mobileHeaderCollapsed: true
  }

  _isLoggedIn = () => {
    return !!this.props.user
  }

  render() {
    var isLoggedIn = this._isLoggedIn()

    return (
      <span>

        <div className={"header " + (this.state.mobileHeaderCollapsed ? 'collapsed' : 'expanded')}>
          <div className="container">

            <Link to={'/'} aria-label="logo home page link">
              <img alt='logo'
                   className='logo showmobile'
                   width='150px'
                   height='51px'
                   src={logo}/>
            </Link>
            <div className="header-open">
              <MenuIcon className="menu-icon" fontSize="large" onClick={() => { this.setState({ mobileHeaderCollapsed: false })}}/>
            </div>

            <div className="fade-content">
              <Box className="header-nav">

                <Box className="logo-nav">
                  <div>
                    <Link to={'/'} aria-label="logo home page link">
                      <img alt='logo'
                           className='logo hidemobile'
                           width='150px'
                           height='51px'
                           src={logo}/>
                    </Link>
                    <div className="header-close"
                         onClick={() => { this.setState({ mobileHeaderCollapsed: true })}}>
                      X
                    </div>
                  </div>
                </Box>

                <Box className="nav-item">
                  <Link to={'/how-it-works'}
                        className='no-link-style'
                        onClick={() => { this.setState({ mobileHeaderCollapsed: true })}}>
                  How it Works
                  </Link>
                </Box>

                <Box className="nav-item">
                  <Link to={'/current-shows'}
                        className='no-link-style'
                        onClick={() => {
                          this.setState({ mobileHeaderCollapsed: true })
                        }}>
                  Shows
                  </Link>
                </Box>

                <Box className="nav-item">
                  <a href="https://broadwayroulette.com"
                        className='no-link-style'
                        onClick={() => { this.setState({ mobileHeaderCollapsed: true })}}>
                  Broadway
                  </a>
                </Box>

                <Box className="nav-item">
                  <Link to={'/gift-card'}
                        className='no-link-style'
                        onClick={() => {
                          this.setState({ mobileHeaderCollapsed: true })
                        }}>
                  Gift Cards
                  </Link>
                </Box>

                <Box className="nav-item login">
                  {!isLoggedIn &&
                    <LoginAuth0 clientId={GRAPHQL_AUTH_CLIENT_ID}
                                domain={GRAPHQL_AUTH_DOMAIN}
                                primaryColor='#d50032'>
                      Log in
                    </LoginAuth0>
                  }

                  {isLoggedIn &&
                    <Link className='no-link-style'
                          to="/account">
                      Account
                    </Link>
                  }
                </Box>

                <Box className="nav-item">
                  <Link to={'/buy-tickets'}
                        className='no-link-style buy-tickets-button'
                        onClick={() => {
                          this.setState({ mobileHeaderCollapsed: true })
                          sendGAEvent(GA_EVENT.INTERACTION, GA_ACTION.INTERACTION.BUY_TICKETS_HEADER)
                        }}>
                    BUY NOW
                  </Link>
                </Box>

              </Box>
            </div>
          </div>
        </div>
      </span>
    )
  }

}

export default Header
